import React from 'react'
import { app } from '../../firebase'
import { useEffect, useState } from 'react'
import { getDatabase, ref, onValue } from "firebase/database"
import {DataGrid} from '@mui/x-data-grid'
import MonthChart from './MonthChart'
import { useNavigate } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
import {IoMdHome} from 'react-icons/io'
import {Link} from 'react-router-dom'



// import {Da} from "@mui/x-data-grid
const MonthGameChart = () => {
  const now = new Date()
  const month = now.toLocaleString('en-US', { month: 'long' })
  const year = now.getFullYear();
  const navigate = useNavigate()
  

  const [catergory,setCatergory] =useState()
  const categoriesData=["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"]
  const [years, setyears] = useState()
  const database = getDatabase(app);
  const dbRef = ref(database,"YearGame")
  const [yearKey, setYearKey] = useState([])
  const [allData,setAllData] = useState([])
  const [dat,setDat] = useState([])
  const [data,setData] = useState([])
  const[cYear,setCYear ] = useState()
  const [cMonth, setCMonth] = useState()


  const [restDate,setRestData] = useState([])
  const [third, setThird] = useState([])

  useEffect(()=>{
    const unsubscribe = onValue(dbRef,(snapshot)=>{
      const snap = snapshot.val()
      if(snap){
        const yearNames = Object.keys(snap)
        setYearKey(yearNames)
        setAllData(snap)

        // setDat(allData[years][month] && allData[years][month])

        // setData(Object.values(dat).map((data) => ({
        //   // key:count,
        //   id: data.Date, 
        //   disawar: data.dis,
        
        //   delhi: data.delhi,
        //   shri:data.shri,
          
        //   faridabad:data.faridabad,
      
        //   gali:data.gali
        // })))
        


      }
    })
    return () => unsubscribe();

  },[])


  const goClick =()=>{
    
    setDat(allData && allData[years][catergory])

    setData(Object.values(dat).map((data) => ({
      // key:count,
      id: data.Date, 
      disawar: data.dis,
    
      delhi: data.delhi,
      shri:data.shri,
      
      faridabad:data.faridabad,
  
      gali:data.gali
    })))
    
    setCYear(years)
    setCMonth(catergory)

   

   
    }
  



  const columns = [
    {field:"id",headerName: "Date", minWidth: 150, flex: 0.7    },
    {field:"disawar",headerName: "DISAWAR", minWidth: 150, flex: 0.7},
   
    {field:"delhi",headerName: "DELHI BAZAAR DL", minWidth: 150, flex: 0.7},
    {field:"shri",headerName: "SHRI GANESH", minWidth: 150, flex: 0.7},
    {field:"faridabad",headerName: "FARIDABAD", minWidth: 150, flex: 0.7},
    {field:"gali",headerName: "GALI", minWidth: 150, flex: 0.7}

]



return (
  <>
  <Header/>
  <div className=' bg-gradient-to-r from-[#7114ef] to-indigo-500 py-3 ' >
  

    <h2 className='text-center text-[20px] text-[white]'>
      If you want to see any specific <span className='font-[600]'> Month </span> and
       <span className='font-[600]'> Year</span> chart please select it below  </h2>
       <Link to='/' className='flex justify-center items-center'>
       
       <h2 className='text-[16px] my-3 text-[white] font-[600] text-center'  >Go To Home   </h2>
        <IoMdHome
        color='white' className='cursor-pointer' size={20}></IoMdHome>
        </Link>

  <div className='flex 800px:flex-row flex-col justify-center items-center my-5 '>
   
  
  <div >
        <label className="pb-2 text-[20px] text-[white] font-[600]">
        Month <span className="text-red-500">*</span>
        </label>
        <select className="w-full mt-2 border rounded-[5px] px-4 py-4"
        value={catergory}

        onChange={(e)=>setCatergory(e.target.value)}

        >
          <option value='Please choose a month'  >
            Choose Month

          </option>
          
          {
              categoriesData && categoriesData.map((i)=>(
                  <option key={i} value={i} className='text-black'>
                      {i}

                  </option>
              ))
          }

        </select>
        
      </div>

      <div  className='800px:mx-10 my-5 '>
        <label className="pb-2 text-[20px] font-[600] text-[white]">
        Year <span className="text-red-500">*</span>
        </label>
        <select className="w-full mt-2 border rounded-[5px] px-4 py-4"
        value={years}

        onChange={(e)=>setyears(e.target.value)}

        >
          <option value='Please choose a month' >
            Choose Year

          </option>
          {
            yearKey && yearKey.map((i)=>(
            <option key={i} value={i} className='text-black'>
              {i}

            </option>

            ))
          }
        
          
        

        </select>
        
      </div>
      <div className='text-[yellow] 800px:mt-8'>
        Please Double Click On Go
      </div>
      <div className='flex  items-center justify-center 800px:mx-10 !mt-5 '> 

     

      <div onClick={()=> goClick()}
      className=' rounded-md  py-2 text-[20px] px-6 font-[600] bg-[#ffffffd3] cursor-pointer'>
        <h2 >GO</h2>

      </div>
      
      </div>


 

  </div>

  </div>

 { data.length > 0 ?
 <div>

  <div className='flex justify-center text-center items-center my-5 px-5 '>
  <h2 className='text-[20px] font-[600] underline '> {cMonth.toUpperCase()}  {cYear} </h2>
  </div>

  <div id="thirdcomponent"
    className='w-[90%]  800px:flex hidden   px-2 mx-5 800px:mx-10 py-2 mt-10 my-5 bg-white'>
     
      
        {data.length > 0 && 
          <DataGrid
          showColumnVerticalBorder={true}
          showCellVerticalBorder ={true}
           rows={data} columns={columns} autoHeight
           sx={{
            '& .MuiDataGrid-columnHeader': {
             backgroundColor: "#ff000065",
             color: "black",
             fontWeight: 700,
          },
          '& .MuiDataGrid-cell':{
            backgroundColor:'#ffc0cb68'
          }}}
           />
          
        }
  </div>  

      <div className='w-full 800px:hidden flex px-1  py-1 mt-10 my-5  bg-white'>
        
        {
          data.length > 0 && (
            <table className=' vertical-borders w-[95%] '>
            <thead>
                <tr className='bg-[#ff000065]  ' >
                    <th className='!px-2 text-[13px] font-[600] '>Date</th>
                    <th className='!px-2 text-[13px] font-[600]'>DISAWAR</th>
                    <th className='!px-3 text-[13px] font-[600]'>DELHI BAZAR DL</th>
                    <th className='!px-2  text-[13px] font-[600]'>SHRI GANESH</th>
                    <th className='!px-2  text-[13px] font-[600]'>FARIDABAD</th>
                    <th className='!px-2  text-[13px] font-[600]'>GALI</th>
                </tr>
            </thead>
            <tbody  >
              {data.map((item)=>(
                  <tr key={item.id} 
                  className=' font-[600] items-center text-center border-black '>
                    <td  className=''> 
                   {item.id}
                    </td>

                    <td 
                    >{item.disawar}
                    </td>
                    <td>{item.delhi}
                    </td>
                    <td>{item.shri}
                    </td>
                    <td>{item.faridabad}
                    </td>
                    <td>{item.gali}
                    </td>
                                  
                  </tr>
              ))}
               
            </tbody>
        </table>

          )
        } 


      </div>

     

      
 


    </div>





:<h2 className='text-center py-3 text-[20px] font-[600] mt-10 '>Please Select Month and Year to see the previous chart</h2>}

   
    </>









)
}

export default MonthGameChart