import React from 'react'
import Header from '../Header'
import Footer from '../Footer'
import {IoMdHome} from 'react-icons/io'
import { useNavigate ,Link} from 'react-router-dom'
const PrivacyPolicy = () => {
  const navigate = useNavigate()
    const GoHome = ()=>{
        navigate('/')
    }
  return (
    <div >
    <Header />
    <div className='h-[71.4vh] overflow-scroll'>
    <div className='w-full  flex justify-start items-center mt-5 p-3'>
        <h2 onClick={()=>GoHome()}
         className='underline cursor-pointer font-[600] text-[18px] text-[#7114ef]
        '> 
        Go Back To Home
        
        </h2>
        <IoMdHome  onClick={()=>GoHome()}
        color='#7114ef' className='cursor-pointer' size={20}></IoMdHome>
        
    </div>

    <div className='p-3 '>
      <h2 className='text-[20px] font-[600]'>

      Fast Satta Results (FSR)
      </h2>

      <h2 className='text-[18px] '>
        Privacy Policy

        Effective Date: [12May2024]</h2>
        <br></br>

      

      <h2 className='font-[600] text-[18px]'>Introduction</h2>

      We at Fast Satta Result, we are committed to protecting the privacy of our users .
       This Privacy Policy explains how we collect, use, disclose, and safeguard any information 
       that you provide to us or that we collect from you when you use our website, mobile application, 
       or other online services (collectively, None).

    <br></br>
      <h2 className='font-[600] text-[18px]'> Information We Collect</h2>
     
      We don't collect your data.

      We take reasonable measures to protect your information from unauthorized access, disclosure, 
      alteration, or destruction. However, no website or internet transmission is completely secure.
       We cannot guarantee the security of your information.

      <br></br>
      <h2 className='font-[600] text-[18px]'>Changes to This Policy</h2>

      

      We may update our Privacy Policy from time to time. We will notify you of any changes by
       posting the new Privacy Policy on the Service.
      <br></br>
      <h2 className='font-[600] text-[18px]'>Contact Us</h2>

      

      If you have any questions about this Privacy Policy, please contact us through whatsapp ,
       we have provide a whatsapp number and also link in our main screen.

    </div>
    </div>

    <div className='w-fulll justify-center items-center'>
      <div>
        <option>
          
        </option>
      </div>

    </div>

    <Footer />
    </div>
  )
}

export default PrivacyPolicy