import React, { useState } from 'react'
import Header from '../Header'
import Footer from '../Footer'
import { Link } from 'react-router-dom'

// import styles from '../Style/style'

import {IoMdHome} from 'react-icons/io'
import { useNavigate } from 'react-router-dom'

const FAQ =()=>{

  const navigate = useNavigate()
  const GoHome = ()=>{
      navigate('/')
  }  
  const [activeTab,setActiveTab] = useState(0)

  const toogleTab = (tab)=>{
    if(activeTab === tab){
      setActiveTab(0)
    }
    else{
      setActiveTab(tab)
    }
  }
  return(
    <>
    <Header/>
    <div className='w-full  flex justify-start items-center mt-5 p-3'>
        <h2 onClick={()=>GoHome()}
         className='underline cursor-pointer font-[600] text-[18px] text-[#7114ef]
        '> 
        Go Back To Home
        
        </h2>
        <IoMdHome  onClick={()=>GoHome()}
        color='#7114ef' className='cursor-pointer' size={20}></IoMdHome>
        
    </div>
    <div className={`w-11/12 mx-auto my-8 `}>
      
      <h2 className='text-3xl font-bold text-gray-900 mb-8'>
        FAQ
      </h2>
      <div className='mx-auto space-y-4'>

        {/* single Faq */}

        <div className='border-b border-gray-200 pb-4'>
         
          <button 
          className='flex items-center justify-between w-full '
          onClick={()=>toogleTab(1)}
          >
            <span className='text-lg font-medium text-gray-900'>
            God Father Of Satta
            </span>
            
              {activeTab === 1 ? 
                (
                <svg
                  className="h-6 w-6 text-gray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  className="h-6 w-6 text-gray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                  />
                </svg>)
            }

           
  
          </button>
          {
              activeTab === 1 && (
                <div className='mt-4'>
                  <p className='text-base text-gray-500'>
                  Satta Game Was Played Offline From Early 1970's.but There Comes The God Father Of Online Satta.in 2010 
                  The Godfather Of Satta THE ONE AND ONLY TASIM KHAN Bring It On An Online Platform.due To Him Today
                   Lakhs Of People Are Playing Online And Earn Money.he Brings Satta Game To The Another Level.now 
                   Everyone Copies Him But The Real Credit Of Bringing Online Satta 
                  Is Totally To Tasim Khan.he Created The Satta- Website.he Is The Godfather Of Online Satta Game.!
                  </p>

                </div>
              )
            }

        </div>


        <div className='border-b border-gray-200 pb-4'>
         
          <button 
          className='flex items-center justify-between w-full '
          onClick={()=>toogleTab(2)}
          >
            <span className='text-lg font-medium text-gray-900'>
            What Is Satta
            </span>
            
              {activeTab === 2 ? 
                (
                <svg
                  className="h-6 w-6 text-gray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  className="h-6 w-6 text-gray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                  />
                </svg>)
            }

           
  
          </button>
          {
              activeTab === 2 && (
                <div className='mt-4'>
                  <p className='text-base text-gray-500'>
                  Satta Is A Satta Game Which Is Very Popular In India.as You Have Heard The Name Satta Many Times From 
                  Your Friends,satta Is Very Popular.satta Game Is Played At Very High Stake In India.the Craze Among The
                   People About The Satta Game Is Increasing At A Very Fast Rate And People Are Getting Addicted To It.
                   satta Game Can Be Very Harmful As You Can Lose Your All Money In This Game.your Hard Earned Money Can
                    Be Gone Within A Day In The Satta Game.below Is The More Information Which We Will Give You About The
                     Satta Game That You All Need To Be Know.we Will Tell You Where The Satta Game Is Played And What Is
                      The Technique By Which You Can Win Or Lose From The Satta Game.we Sincerely Tell You To Read All 
                      The Rules And Regulations And Disclaimer Before Proceeding Further,it Will Help You To Know About 
                      The Game More.some Advantages And Disadvantages Will Be Told 
                  To You About The Satta Game.satta Game Is Very Popular So You Should Know Each And Everything About It
                   </p>

                </div>
              )
            }

        </div>




        <div className='border-b border-gray-200 pb-4'>
         
          <button 
          className='flex items-center justify-between w-full '
          onClick={()=>toogleTab(3)}
          >
            <span className='text-lg font-medium text-gray-900'>
              About Us 
            </span>
            
              {activeTab === 3 ? 
                (
                <svg
                  className="h-6 w-6 text-gray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  className="h-6 w-6 text-gray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                  />
                </svg>)
            }

           
  
          </button>
          {
              activeTab === 3 && (
                <div className='mt-4'>
                  <p className='text-base text-gray-500'>
                  <Link to='/' className="underline text-[#0000ffe0]">WWW.fastsattaresults.com</Link> NO. 1 WEBSITE IN WORLD
                    When Satta Came Online There Was Only One Website Which Is Satta- The Word Satta Was 
                    Also Founded From This Website.all The Other Websites Copied The Satta Website.but In 
                    The Market Satta- Has Its Own Reputation.peoples Trust Blindly On Satta-.in Website.
                    even Khaiwals Only Trust The Result Which Is Provided By This Website.satta Website Is 
                    Famous All Over The World And Is No.1.many Websites Come And Go But They Were Not Able 
                    To Take Place Of Satta-.our Website Is No.1 From Starting Because We Do Not Mis Guide
                     People..

                  </p>

                </div>
              )
            }

        </div>



        <div className='border-b border-gray-200 pb-4'>
         
          <button 
          className='flex items-center justify-between w-full '
          onClick={()=>toogleTab(4)}
          >
            <span className='text-lg font-medium text-gray-900'>
            Popular Satta Game
            </span>
            
              {activeTab === 4 ? 
                (
                <svg
                  className="h-6 w-6 text-gray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  className="h-6 w-6 text-gray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                  />
                </svg>)
            }

           
  
          </button>
          {
              activeTab === 4 && (
                <div className='mt-4'>
                  <p className='text-base text-gray-500'>
                  In Satta There Are Lots Of Companies.every Company Has Its Own Result.but In Todays Market Here Are
                   Some Games In Which You Can Invest And Trust Blindly Because These Are Very Popular And People Win
                    Very Much Amount Of Money From Them.here Are Some Popular Satta Games.dishawar, Delhi Darbar, 
                    Faridabad, Ghaziabad,taj,new
                   Faridabad,delhi Noon,punjab Day,delhi Day And Punjab Special.every Company Has Its Own Policy And Rate.
                  </p>

                </div>
              )
            }

        </div>



        <div className='border-b border-gray-200 pb-4'>
         
          <button 
          className='flex items-center justify-between w-full '
          onClick={()=>toogleTab(5)}
          >
            <span className='text-lg font-medium text-gray-900'>
            How To Play Satta ?
            </span>
            
              {activeTab === 5 ? 
                (
                <svg
                  className="h-6 w-6 text-gray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  className="h-6 w-6 text-gray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                  />
                </svg>)
            }

           
  
          </button>
          {
              activeTab === 5 && (
                <div className='mt-4'>
                  <p className='text-base text-gray-500'>
                  Satta Game Can Be Played Both Online And Offline.in Satta Game There Are Number From 1 To 100.these 
                  Number Are Put In The Pot And One Number Is Taken Out From It.the Satta Player Which Has Same Number
                   Matched Wins The Amount Of Money.you Can Put Certain Amount Of Money On One Or More Numbers,if Your 
                   Number Is Matched With That Number Than You Will Get The 90 Times The Amount Of Money You Had Put On 
                   It.in This You Can Put Money On Single Digit Also Which Is Known As Andar Bahar.for Eg The Number Is 98.in This 9 Is Andar 8 Is Bahar.if You Want To Play Andar Bahar You Can Put Money On Single Digit.if Your Digit Matched Then You Will Get 9 Times The Money You Have Put On It.there Are Many Kinds By Which You Can Play Satta Game.in This Game You Can Lose All Your Money In It.chances Of Winning In Satta Game Is Very Loss Because Company Only Opens Those Numbers Onto Which Less Money Is Put.company Put All The Numbers In Software And See Which 
                  Number Has Less Amount Of Money And Open That Number Only.so Be Very Careful While Playing Satta Gme.
                  </p>

                </div>
              )
            }

        </div>


        <div className='border-b border-gray-200 pb-4'>
         
         <button 
         className='flex items-center justify-between w-full '
         onClick={()=>toogleTab(6)}
         >
           <span className='text-lg font-medium text-gray-900'>
           How To Betting Satta?
           </span>
           
             {activeTab === 6 ? 
               (
               <svg
                 className="h-6 w-6 text-gray-500"
                 fill="none"
                 viewBox="0 0 24 24"
                 stroke="currentColor"
               >
                 <path
                   strokeLinecap="round"
                   strokeLinejoin="round"
                   strokeWidth={2}
                   d="M6 18L18 6M6 6l12 12"
                 />
               </svg>
             ) : (
               <svg
                 className="h-6 w-6 text-gray-500"
                 fill="none"
                 viewBox="0 0 24 24"
                 stroke="currentColor"
               >
                 <path
                   strokeLinecap="round"
                   strokeLinejoin="round"
                   strokeWidth={2}
                   d="M9 5l7 7-7 7"
                 />
               </svg>)
           }

 
         </button>
         {
             activeTab === 6 && (
               <div className='mt-4'>
                 <p className='text-base text-gray-500'>
                 You can play satta online and offline as well as in the area state where you live, there are agents of
                  the company, to write your bet, who is known as Khaiwal, you go to them and by giving them money, you 
                  will get their number. Money has to be invested, he has to be told, suppose you have to put 100 rupees
                   on number 22, then you have to tell them that put my money on number 22, you will put your money on 
                   the same number and give you a small slip in which you will have written On which number, how much 
                   money have you invested, now after that game on which you invested money, if the same number opens,
                    then you will have to go to Khaiwal with the same slip, he will match the slip with his pass and pay 
                    you, the match will be done because because A duplicate slip
                  is also with the Khaiwal, otherwise anyone can make a slip from his own and go to collect the payment.
                 </p>

               </div>
             )
           }

       </div>

       <div className='border-b border-gray-200 pb-4'>
         
         <button 
         className='flex items-center justify-between w-full '
         onClick={()=>toogleTab(7)}
         >
           <span className='text-lg  font-medium text-gray-900'>
          Jodi And Haruf ?
           </span>
           
             {activeTab === 7 ? 
               (
               <svg
                 className="h-6 w-6 text-gray-500"
                 fill="none"
                 viewBox="0 0 24 24"
                 stroke="currentColor"
               >
                 <path
                   strokeLinecap="round"
                   strokeLinejoin="round"
                   strokeWidth={2}
                   d="M6 18L18 6M6 6l12 12"
                 />
               </svg>
             ) : (
               <svg
                 className="h-6 w-6 text-gray-500"
                 fill="none"
                 viewBox="0 0 24 24"
                 stroke="currentColor"
               >
                 <path
                   strokeLinecap="round"
                   strokeLinejoin="round"
                   strokeWidth={2}
                   d="M9 5l7 7-7 7"
                 />
               </svg>)
           }

          
 
         </button>
         {
             activeTab === 7 && (
               <div className='mt-4'>
                 <p className='text-base text-gray-500'>
                 Jodi means a single number from 1 to 100, you invest your money on any single number like suppose you 
                 put 500 rupees on number 22, it means you have invested money on a pair. Haruf means that if you put 
                 money on 5 haruf, then any number from 1 to 100 on which it is in 5 units, then your haruf will be 
                 considered as pass like understand 05 25 35 65 95 all of these 5 numbers are in units and If any of 
                 these numbers is opened then you will take money but there is a different rule in it like if you invest
                  money on any pair, it opens then you will get 90 rupees of 1 rupee, same if you invest money in any 
                  haruf then you will get 1 rupee You will get only 9 rupees not 90 rupees because now the chances of 
                  opening your numbers have increased, the more risk you take in this betting game, the more profit you
                   will get Inside out means as if you played Haruf and you invested your money in 5 Haruf, it is played
                    in the same way as outside means Haruf only, just inside means you have to understand as if it was
                     in 5 units in Haruf or Bahar like 05 25 15 95 in the same way 5 will be in tens in the same way 51
                      55 59 52 Now you see whatever 5 is in it is on the inside side it means 5 is open inside 
                 in this also you will get 90 of 1 rupee not 9 why that your chances of getting it too are very high
                 </p>

               </div>
             )
           }

       </div>





       <div className='border-b border-gray-200 pb-4'>
         
         <button 
         className='flex items-center justify-between w-full '
         onClick={()=>toogleTab(8)}
         >
           <span className='text-lg font-medium text-gray-900'>
           How Can We Play Betting Online?
           </span>
           
             {activeTab === 8 ? 
               (
               <svg
                 className="h-6 w-6 text-gray-500"
                 fill="none"
                 viewBox="0 0 24 24"
                 stroke="currentColor"
               >
                 <path
                   strokeLinecap="round"
                   strokeLinejoin="round"
                   strokeWidth={2}
                   d="M6 18L18 6M6 6l12 12"
                 />
               </svg>
             ) : (
               <svg
                 className="h-6 w-6 text-gray-500"
                 fill="none"
                 viewBox="0 0 24 24"
                 stroke="currentColor"
               >
                 <path
                   strokeLinecap="round"
                   strokeLinejoin="round"
                   strokeWidth={2}
                   d="M9 5l7 7-7 7"
                 />
               </svg>)
           }

          
 
         </button>
         {
             activeTab === 8 && (
               <div className='mt-4'>
                 <p className='text-base text-gray-500'>
                 As you all know, now we can do money transactions very easily with anyone at any time, as you have read above, you go to your Khaiwal to play betting, then add your numbers to him, whatever you need inside / outside. He also tells you to play, then he gives you the slip, this process we call to play offline betting, it has its own advantage and disadvantage too. How is Khaiwal, you do not want to come to pay, you are familiar with it, but you also have a risk in it, you got the bet written, Khaiwal gave you the slip, if that slip is lost from you, then Khaiwal can also refuse to pay you And the second risk is that sometimes you went to Khaiwal to write betting and at the same time there was a police raid, then you can also be arrested or you will believe that because according to the law of the Government of India, both playing betting and playing. It is a legal crime, for which you can be fined heavily and can be put in jail, now you have to play online. I also tell the advantages and disadvantages, as you have read upen, you know the work with which you get your bet written, but you do not know anyone in online because there is no one who comes to know about the online betting player. You can talk to him only through phone whats app sms, if he does not want to talk to you or does not want to pay you, even then you cannot do anything because you do not know anything about that gap, you do not even go to the police Otherwise you can get in trouble, this is its disadvantage, now also understand the advantages of online, whether you are at home or office or traveling, just need to have internet or calling in your phone, you can get your bet written from any place. also lives with you and if you are honest then you can also do your payment transactions very easily PAYTM PHONEPE GOOGLEPAY and there are many more facilities you can use anyone
                  </p>

               </div>
             )
           }

       </div>



       <div className='border-b border-gray-200 pb-4'>
         
         <button 
         className='flex items-center justify-between w-full '
         onClick={()=>toogleTab(9)}
         >
           <span className='text-lg font-medium text-gray-900'>
           Where Is Satta Played?
           </span>
           
             {activeTab === 9 ? 
               (
               <svg
                 className="h-6 w-6 text-gray-500"
                 fill="none"
                 viewBox="0 0 24 24"
                 stroke="currentColor"
               >
                 <path
                   strokeLinecap="round"
                   strokeLinejoin="round"
                   strokeWidth={2}
                   d="M6 18L18 6M6 6l12 12"
                 />
               </svg>
             ) : (
               <svg
                 className="h-6 w-6 text-gray-500"
                 fill="none"
                 viewBox="0 0 24 24"
                 stroke="currentColor"
               >
                 <path
                   strokeLinecap="round"
                   strokeLinejoin="round"
                   strokeWidth={2}
                   d="M9 5l7 7-7 7"
                 />
               </svg>)
           }

          
 
         </button>
         {
             activeTab === 9 && (
               <div className='mt-4'>
                 <p className='text-base text-gray-500'>
                 Game Is Mostly Played In Northern Part Of India.mainly In Punjab Up Delhi Haryana And Some Other Parts Also.it Is Not That Kind That Satta Is Played Only In Northern Part It Is Played All Over India As Well As In Other Countries Also.satta Offers You A Lot.one Can Play It Both Offline As Well As Online Medium
                  </p>

               </div>
             )
           }

       </div>


       <div className='border-b border-gray-200 pb-4'>
         
         <button 
         className='flex items-center justify-between w-full '
         onClick={()=>toogleTab(10)}
         >
           <span className='text-lg font-medium text-gray-900'>
           Satta Is Real?
           </span>
           
             {activeTab === 10 ? 
               (
               <svg
                 className="h-6 w-6 text-gray-500"
                 fill="none"
                 viewBox="0 0 24 24"
                 stroke="currentColor"
               >
                 <path
                   strokeLinecap="round"
                   strokeLinejoin="round"
                   strokeWidth={2}
                   d="M6 18L18 6M6 6l12 12"
                 />
               </svg>
             ) : (
               <svg
                 className="h-6 w-6 text-gray-500"
                 fill="none"
                 viewBox="0 0 24 24"
                 stroke="currentColor"
               >
                 <path
                   strokeLinecap="round"
                   strokeLinejoin="round"
                   strokeWidth={2}
                   d="M9 5l7 7-7 7"
                 />
               </svg>)
           }

          
 
         </button>
         {
             activeTab === 10 && (
               <div className='mt-4'>
                 <p className='text-base text-gray-500'>
                 There Are Lot Of Rumors In Market That Whether Satta Game Is Real Or Fake.we Can Assure You That Satta- Game Is 100% Real.those Who Say Satta Game Is Fake Are Fooling You.we Will Give You Further Clarification Regarding This.in Small Villages There Can Be Small Fraud.because In Satta Kig There Are Some Khaiwals Which Rest All The Satta In Their Pocket Only.they Do Not Send Those Satta To The Satta Company.when The Number Is Opened They Try To Fool The Satta Game Players.satta Is Real If You Play With The Good Peoples.you Can Invest Your Money In Satta Game If You Can Guess The Confirmed Number Of The Upcoming Results.                  </p>

               </div>
             )
           }

       </div>

      </div>


      


    </div>
    <Footer/>

    </>
    
  )
}

export default FAQ