import React from 'react'
import Header from '../Header'
import Footer from '../Footer'
import {IoMdHome} from 'react-icons/io'
import { useNavigate ,Link} from 'react-router-dom'


const Disclaimer = () => {
  const navigate = useNavigate()
  const GoHome = ()=>{
      navigate('/')
  }
  return (
    <div>
    <Header/>
    <div className='overflow-y-auto overflow-x-auto'>

    
    <div className='w-full  flex justify-start items-center mt-5 p-3'>
        <h2 onClick={()=>GoHome()}
         className='underline cursor-pointer font-[600] text-[18px] text-[#7114ef]
        '> 
        Go Back To Home
        
        </h2>
        <IoMdHome  onClick={()=>GoHome()}
        color='#7114ef' className='cursor-pointer' size={20}></IoMdHome>
        
    </div>

    <div className='p-3 w-full  '>
      <h2 className='text-[20px] font-[600]'>Disclaimer</h2>
        
<br></br><br></br>
<h2 className='text-[18px] font-[550]'>Website Purpose:</h2> All content, software, 
and materials on <Link to='/' className="underline text-[#0000ffe0]">WWW.fastsattaresults.com</Link> are provided for informational and entertainment 
purposes only. Users should use their judgment and exercise caution where appropriate. 
Your use of our site is solely at your own risk.
<br></br>

<h2 className='text-[18px] font-[550]'>

  Accuracy of Information: </h2>

All the information on this website is published in good faith and for general information purpose only.
<Link to='/' className="underline text-[#0000ffe0]">WWW.fastsattaresults.com</Link>
does not make any warranties about the completeness, reliability, or accuracy of this information.
 Any action you take based on the information on this website is strictly at your own risk.
<br></br>
<h2 className='text-[18px] font-[550]'>Limitation of Liability:</h2>

Any losses, damages, or injuries from the display or use of this information or from any information, 
products, or services provided through this website, even if <Link to='/' className="underline text-[#0000ffe0]">WWW.fastsattaresults.com</Link> has been advised of 
the possibility of such damages.
<br></br>
<h2 className='text-[18px] font-[550]'>External Links:</h2>


Our website provides links to external communication services like WhatsApp for user
 interaction. Please note that interactions through these links are subject to the terms 
 and conditions of the respective platforms.

<h2 className='text-[18px] font-[550]'>Amendments</h2>


We reserve the right to amend or modify this disclaimer at any time without notice. 
It is your responsibility to review this disclaimer periodically.

    </div>
    </div>
    <Footer/>
    </div>
  )
}

export default Disclaimer