import React from 'react'
import { app } from '../../firebase'
import { useEffect, useState } from 'react'
import { getDatabase, ref, onValue } from "firebase/database"
import {DataGrid} from '@mui/x-data-grid'


// import {Da} from "@mui/x-data-grid"

const MonthChart = () => {
  const now = new Date()
  const month = now.toLocaleString('en-US', { month: 'long' })
  const year = now.getFullYear();
  
  const database = getDatabase(app);
  const dbRef = ref(database,"YearGame/" + year + "/" + month)
  const [data,setData] = useState([])
  const [restDate,setRestData] = useState([])
  const [third, setThird] = useState([])
  let count = 1
  useEffect(()=>{
    const unsubscribe = onValue(dbRef, (snapshot) => {
        const snap = snapshot.val();
        if(snap){

        
        setData(Object.values(snap).map((mydata) => ({
          // key:count,
          id: mydata.Date, 
          disawar: mydata.dis,
          wazirabad: mydata.waz,
          taj: mydata.taj,
          delhi: mydata.delhi,
        })));
       

        setThird(Object.values(snap).map((data) => (
          {
          // key:count,
          id: data.Date, 
          delhi: data.delhi,
          shri:data.shri,
          jaipur:data.jaipur,
          faridabad:data.faridabad,
          ghaz:data.ghaz,
          gali:data.gali
        }
        )));
        

        setRestData(Object.values(snap).map((data)=>(
          {
            // key:count++,
            id:data.Date,
            shri:data.shri,
            jaipur:data.jaipur,
            faridabad:data.faridabad,
            ghaz:data.ghaz,
            gali:data.gali
          }
        )))


        }
        

      }
      
      
      );
      
      
    return () => unsubscribe();
    
  },[])

    const columns = [
        {field:"id",headerName: "Date", minWidth: 150, flex: 0.7 
      },
        {field:"disawar",headerName: "DISAWAR", minWidth: 150, flex: 0.7},
        {field:"wazirabad",headerName: "WAZIRABAD", minWidth: 150, flex: 0.7},
        {field:"taj",headerName: "TAJ", minWidth: 150, flex: 0.7},
        {field:"delhi",headerName: "DELHI BAZAAR DL", minWidth: 150, flex: 0.7}

    ]
    
    const col =[
      {field:"id",headerName: "Date", minWidth: 150, flex: 0.7},
      {field:"shri",headerName: "SHRI GANESH", minWidth: 150, flex: 0.7},
      {field:"jaipur",headerName: "JAIPUR", minWidth: 150, flex: 0.7},
      {field:"faridabad",headerName: "FARIDABAD", minWidth: 150, flex: 0.7},
      {field:"ghaz",headerName: "GHAZIABAD", minWidth: 150, flex: 0.7},
      {field:"gali",headerName: "GALI", minWidth: 150, flex: 0.7}


    ]
 



  return (
    <>
   
   <div id="thirdcomponent"
    className='w-[90%]  800px:flex hidden   px-2 mx-5 800px:mx-10 py-2 mt-10 my-5 bg-white'>
        {data.length > 0 && ( // Only render the DataGrid if data exists
          <DataGrid
          showColumnVerticalBorder={true}
          showCellVerticalBorder ={true}
           rows={data} columns={columns} autoHeight
           sx={{
            '& .MuiDataGrid-columnHeader': {
             backgroundColor: "#ff000065",
             color: "black",
             fontWeight: 700,
          },
          '& .MuiDataGrid-cell':{
            backgroundColor:'#ffc0cb68'
          }
        }}
           />
          
        )}
      </div>
      <div className='w-[90%]  800px:flex hidden  mx-5 800px:mx-10 py-2 px-2 my-5 mt-10 bg-white'>
        {restDate.length > 0 && ( // Only render the DataGrid if data exists
          <DataGrid 
          co
          
          howColumnVerticalBorder={true}
          showCellVerticalBorder ={true}
           rows={restDate} columns={col} 
          
           autoHeight 
           sx={{
            '& .MuiDataGrid-columnHeader': {
             backgroundColor: "#ff000065",
             color: "black",
             fontWeight: 700,
          },
          '& .MuiDataGrid-cell':{
            backgroundColor:'#ffc0cb68'
          }
        }}

           />
          
        )}
      </div>



      <div className='w-full 800px:hidden flex px-1  py-1 mt-10 my-5  bg-white'>
        
        {
          data.length > 0 && (
            <table className=' vertical-borders w-[95%] '>
            <thead>
                <tr className='bg-[#ff000065]  ' >
                    <th className='!px-2 text-[15px] font-[600] '>Date</th>
                    <th className='!px-2 text-[15px] font-[600]'>DISAWAR</th>
                    <th className='!px-3 text-[15px] font-[600]'>WAZIRABAD</th>
                    <th className='!px-5  text-[15px] font-[600]'>TAJ</th>
                </tr>
            </thead>
            <tbody  >
              {data.map((item)=>(
                  <tr key={item.id} 
                  className=' font-[600] items-center text-center border-black '>
                    <td  className=''> 
                   {item.id}
                    </td>

                    <td 
                    >{item.disawar}
                    </td>
                    <td>{item.wazirabad}
                    </td>
                    <td>{item.taj}
                    </td>
                                  
                  </tr>
              ))}
               
            </tbody>
        </table>

          )
        } 


      </div>
      
      <div className='w-full 800px:hidden flex px-1   py-1 mt-10 my-5 bg-white'>
        
        {
          third.length > 0 && (
            <table className='vertical-borders w-[96%] '>
            <thead>
                <tr className='bg-[#ff000065]'>
                    <th className='!px-2 text-[15px] font-[600] '>Date</th>
                    <th className='text-[14px] font-[600]'>DELHI BAZAAR DL</th>
                    <th className='text-[14px] font-[600]'>SHRI GANESH</th>
                    <th className='!px-2 text-[14px] font-[600]'>JAIPUR</th>
                   

                </tr>
            </thead>
            <tbody  >
              {third.map((item)=>(
                  <tr key={item.id} 
                  className='font-[600] items-center text-center '>
                    <td > 
                   {item.id}
                    </td>
                    <td>{item.delhi}
                    </td>

                    <td 
                    >{item.shri}
                    </td>
                    <td>{item.jaipur}
                    </td>
                    
                                 
                  </tr>
              ))}
               
            </tbody>
        </table>

          )
        } 

      </div>


      <div className='w-full 800px:hidden flex px-1 !mr-2 ml-1  py-1 mt-10 my-5 bg-white'>
        
        {
          third.length > 0 && (
            <table className=' vertical-borders w-[95%] '>
            <thead>
               <tr className='bg-[#ff000065]' >

                    <th className='!px-2 text-[15px] font-[600] '>Date</th>
                    <th className='!px-2 text-[15px] font-[600]'>FARIDABAD</th>

                    <th className='!px-3 text-[15px] font-[600]'>GHAZIABAD</th>
                    <th className='!px-4 text-[15px] font-[600]'>GALI</th>
                   
                </tr>
            </thead>

            <tbody >

            {third.map((item)=>(
                  <tr key={item.id} 
                  className='font-[600] items-center text-center  '>
                    <td>
                      {item.id}
                    </td>
                    <td > 
                   {item.faridabad}
                    </td>
                    <td > 
                 
                   {item.ghaz}
                  
                    </td>
                    <td > 
                    {item.gali}
                   
                    </td>

                    
                                 
                  </tr>
              ))
              }
               
             
               
            </tbody>
        </table>

          )
        } 

      </div>

      




    </>
  )
}

export default MonthChart