import React from 'react'
import Footer from '../Footer'
import Header from '../Header'
import {IoMdHome} from 'react-icons/io'
import { useNavigate ,Link} from 'react-router-dom'


const TermAndCondition = () => {
    const navigate = useNavigate()
    const GoHome = ()=>{
        navigate('/')
    }
  return (
    <>
    <Header/>
    <div className='h-[71.4vh] overflow-scroll'>

    <div className='w-full  flex justify-start items-center mt-5 p-3'>
        <h2 onClick={()=>GoHome()}
         className='underline cursor-pointer font-[600] text-[18px] text-[#7114ef]
        '> 
        Go Back To Home
        
        </h2>
        <IoMdHome  onClick={()=>GoHome()}
        color='#7114ef' className='cursor-pointer' size={20}></IoMdHome>
        
    </div>


    <div className='  z-10 p-3 overflow-y-auto '>
        <h2 className='text-[20px] font-[600]'>Terms and Conditions</h2>
        
        
<br></br>
<h2 className='text-[18px] font-[600]'>
Thank users for visiting 
<strong> FastSattaResults.com.</strong>

</h2>

<br></br>

<h2 className='text-[18px] font-[600]'>Acceptance of Terms:</h2>


Clearly state that by accessing or using the Website, users agree to be bound by these Terms.
If users disagree with any part of the Terms, they should not access or use the Website.

<br></br>

<h2 className='text-[18px] font-[600]'>Modifications to Terms:</h2>




Reserve the right to modify these Terms at any time without prior written notice.
Recommend users to periodically review the Terms to stay informed of updates.
<br></br>

<h2 className='text-[18px] font-[600]'>Reliability:</h2>

We commit to ensuring all games on <Link to='/' className="underline text-[#0000ffe0]">WWW.fastsattaresults.com</Link> are fair

<br></br>

<h2 className='text-[18px] font-[600]'>
Illegal activity:</h2>
Users are not allowed to use bots, cheats, or any other unauthorized software/tools while using our website. 7.2. Money laundering or any fraudulent activities are strictly prohibited.

<br></br>

<h2 className='text-[18px] font-[600]'>
Limitation of Liability:</h2>

We're not responsible for any losses, damages, or injuries resulting from using <Link to='/' className="underline text-[#0000ffe0]">WWW.fastsattaresults.com</Link>.

<br></br>

<h2 className='text-[18px] font-[600]'>
Termination:</h2>


Reserve the right to terminate a user's access to the Website for violation of the Terms.
<br></br>

<h2 className='text-[18px] font-[600]'>
Governing Law and Jurisdiction:</h2>


These Terms and Conditions are governed by [Applicable Jurisdiction/Law].

<br></br>

<h2 className='text-[18px] font-[600]'>
Entire Agreement:</h2>

We may revise these terms at any time. Users are responsible for reviewing regularly.

<br></br>

<h2 className='text-[18px] font-[600]'>
Contact Us:</h2>

For any questions regarding these Terms and Conditions, please contact us, we have
 provided the whatsapp link in our main screen.


    </div>
    </div>
    <Footer/>
    </>
  )
}

export default TermAndCondition