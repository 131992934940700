import {initializeApp } from 'firebase/app'
import { getAnalytics } from "firebase/analytics"
 
const firebaseConfig = {
  apiKey: "AIzaSyBGXiPUSEZzfsqQSdPlLw6Vl63MWMchrrI",
  authDomain: "fastsatta-676af.firebaseapp.com",
  projectId: "fastsatta-676af",
  storageBucket: "fastsatta-676af.appspot.com",
  messagingSenderId: "682020384354",
  appId: "1:682020384354:web:a2db9188f22cb8d1592b3b",
  measurementId: "G-FZZGFPVXWL"
};


export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
