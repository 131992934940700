import React, { useEffect, useState } from 'react'
import ProductCard from './Component/ProductCard'
import { getDatabase, ref, onValue } from "firebase/database"
import { app } from '../firebase';
import MonthChart from './Component/MonthChart';
import {FaWhatsapp} from 'react-icons/fa6'
import {IoCall} from  'react-icons/io5'
import { Link } from 'react-router-dom';
import imagee from './logo 3.JPG'
import Footer from './Component/Footer';

import Header from './Component/Header';
import MonthGameChart from './Component/MonthGameChart';

function Home() {
  const database = getDatabase(app);
  const db = ref(database,"Game")
  const openResult = ref(database, "OpenResult")
  const [data,setData] = useState([])
  const [result,setResult] = useState()
  const date = new Date();
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0'); // Add leading zero for single-digit minutes

  const meridiem = hours >= 12 ? 'PM' : 'AM';
  const adjustedHours = hours % 12 || 12; // Convert to 12-hour format with 12 displayed at noon


  useEffect(()=>{
    onValue(db,(snapshot)=>{
      const snap = snapshot.val()
      setData(snap)
    })

    onValue(openResult,(snapshot)=>{
      const snap = snapshot.val()
      
      setResult(snap)
    })
    
    // return () => db.off();
  },[])
  return (
    <div className='flex w-full h-full flex-col '>
      <Header/>
      {/* {console.log(result)} */}

      <div className='w-[98%]  p-2 800px:ml-3
       800px:mr-3  mt-2 overflow-hidden  bg-[#ffffffb9]'>

        <h2 className='text-[#7114ef] font-[600] text-[13px]  
        Line w-full'>
        DISAWAR 05:00 AM, WAZIRABAD 01:45 PM, TAJ 03:00 PM, DELHI BAZAAR DL 03:00 PM, SHRI GANESH 04:15 PM, JAIPUR 5.00 PM, FARIDABAD 06:00 PM, GHAZIABAD 08:30 PM, GALI 11:45 PM
        </h2> 

      </div>
      <div className='flex flex-col px-5 py-5 
       text-[red]  justify-center items-center w-full my-2 text-3xl drop-shadow-md '>
        <h2 className='text-center text-[50px] mb-10 font-[700] mr-5'> Time: </h2> 
        <h2 className='font-[700] text-[50px] '> { adjustedHours} : {minutes} {meridiem}
        
  
 </h2>
        </div>

        {/* <div className='flex flex-row justify-center items-center w-full my-2'>
          </div> */}
      <div className='w-full flex flex-col p-5 justify-center items-center'>
        <h2 className=' text-[35px] font-[800]  text-[#000000be] shadow-inner'>Fast Satta Results</h2>

        <h5 className='text-[22px] text-[#00000098] p-2'>
        दो दिन मे सारा लॉस पुरा कवर कराने की गारेंटी है कर्जदार लोग और मोटा खेलने वाले सबसे पहले संपर्क करे 
        </h5>
        <h5 className='text-[22px] mb-5 text-[#00000098] '> 
        whaatsaap करे और अभी से कमाई चालू करे आज से तुम अपनी सोच बदलो मे तुम्हारी तकदीर बदल दूंगा
        </h5>
        { result && (
        <div className='my-15 '>
        <h1 className='font-[800] text-[white]  shadow-[black] Blink gametextcolor
        rounded-lg bg-gradient-to-r from-[orange] to-[red] px-4
         text-[40px] py-5 shadow-xl' > 
          {result && result.open} </h1>
          <h1 className='font-[800] text-[white]  shadow-[black] Blink gametextcolor text-center
        rounded-lg bg-gradient-to-r from-[orange] to-[red] px-4 my-10
         text-[40px] py-5 shadow-xl' > 
          {result && result.alreadyOpen} </h1>
          </div>
        )
}
         

        <h1 className='p-3 my-5 text-[30px] font-[600] ZoomInOUt shadow-sm'>Deepak Bhai Khaiwal</h1>

        <div className='flex justify-center w-full mt-5 items-center  '
       
        >
          <Link to={`tel:${8130323061}`}>
          
          <div className=' flex items-center ShakeAnim bg-[#7114ef] rounded-md mr-3  '>
          <IoCall size={26} className='ml-2' fontSize={18}
           color='white'>

          </IoCall>
          
          <button className='p-3 font-[600] shadow-2xl text-[19px]  text-[white]  ' 
          > 
         
          Call Now

          </button>
          </div>
          </Link>
          <Link to='https://wa.me/${8130323061}'>
          <div className='flex ShakeAnim bg-[#1c8420] items-center rounded-md '>
          <FaWhatsapp className='ml-2' color='white' size={25}>
          </FaWhatsapp>
          <button className='p-3 font-[600] shadow-2xl text-[20px]   text-[white] rounded-md ' 
          > 
          
          whatsapp

          </button>
          </div>
          </Link>
        </div>
       


      </div>

      
      <>
      <div className='w-full'>
      <img src={imagee}  className=' !w-[95%] ml-4 logo  '> 

</img>
        </div>
      

      <div className='w-11/12   mx-auto mt-10   800px:mt-10'>
     
      
    <div className='grid  grid-cols-1 
         gap-[20px] md:grid-cols-2 md:gap-[35px] 
          lg:grid-cols-4 lg:gap-[35px] 
           mb-10 border-0'>
            
            {/* <h2>{data}</h2> */}
            {
               Object.entries(data).map(([key, value])=>
                
             <>
                    <ProductCard ke={key} ne={value.new} old={value.old} time={value.time}></ProductCard> 
                    </>
              
               )
            }
            
            

          </div>
          


      </div>
      
      
      
    </>
    <div>
    <div className='w-full flex flex-col p-5 justify-center items-center'>
        <h2 className=' text-[35px] font-[800]  text-[#000000be] shadow-inner'>Fast Satta Results</h2>

        <h5 className='text-[22px] text-[#00000098]   text-center p-2'>
       
      हमारी दी हुई गेम कोई नहीं रोक सकता जितना मरजी मोटा खेलो गेम 101% वास्तविक हैं
        </h5>
        <h5 className='text-[22px] text-[#00000098]  text-center '> 
      हमसे जुड़े और 20-25 लाख रुपये महीना कमाओ
        </h5>
        <h1 className='p-3 text-[30px] rounded-xl my-5 font-[600] 
        ZoomInOUt bg-[#008000df] text-[#ffffffec] shadow-sm'>

        
          <Link to='https://wa.me/${8130323061}' className='flex justify-center items-center'>
          <FaWhatsapp className='mr-2' color='white' size={25}>
          </FaWhatsapp>
        WhatsApp </Link>


        </h1>




      </div>


      </div>



    <div className='w-full BodyAnim '>
      <div className='w-full text-center flex justify-center items-center'>
      <h2 className='text-[30px] 800px:text-[40px] shadow-lg shadow-zinc-700 text p-2  
      font-[800] Flip'>MONTH CHART</h2>
      </div>
      <div> <h2 className='text-[black] font-[600] text-[22px] text-center py-5 mt-5'>
         Want to see the Previous Year Chart  <span>  </span>
         <Link to={'/yearchart'}
       className='text-[#0000ff] underline'>Click Here
       
       </Link></h2> </div>

      
    <MonthChart></MonthChart>

      
    </div>

    {/* <div className='flex 800px:hidden w-full'>
      <div className='w-full text-center flex justify-center items-center'>
          <h2 className='text-[30px] 800px:text-[40px] shadow-lg shadow-zinc-700 text p-2  
          font-[800] '>MONTH CHART</h2>
        </div>
      <MobileMonthChart></MobileMonthChart>

    </div> */}

    <div className='w-full flex justify-center items-center bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 py-3'>
      <h2 className='text-white font-[600] text-[22px] text-center py-5 '>
         Want to see the Previous Year Chart  <span>  </span>
         <Link to={'/yearchart'}
       className='text-[#0000ff] underline'>Click Here
       
       </Link></h2>
    </div>

    <div className='w-[100wh] '>
    <Footer/>
    </div>
   
    


    </div>
  )
}

export default Home