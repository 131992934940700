import { Minimize } from '@mui/icons-material';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import MonthChart from './MonthChart';


const ProductCard = ({ke, old, time,ne}) => {
     const [ftime,setFtime] = useState()
     const [fmin,setFMin] = useState()
     const [remainingTime, setRemainingTime] = useState(0);
     let  key = ke.split("  ")[1]
     const navigate = useNavigate()
     const month =()=>{
        
     }

     // current date and time
     useEffect(()=>{
     const now = new Date();
     const day = now.getDate();
     const hours = now.getHours();
     const minutes = now.getMinutes();
     const seconds = now.getSeconds();
     // console.log("day",day,"hour",hours,"min",minutes);

     const theretime = time.split(" ")[0]
     const period = time.split(" ")[1]
     // console.log("period",period);
     let t = parseInt(theretime.split(":")[0] )
     let m = theretime.split(":")[1]
     let atime = t


     if(period == "PM" ){
           atime = t + 12
     }
    
     let hoursDifference = atime - hours
     let minutesDifference = m - minutes
     
    
     if (minutesDifference < 0) {
          minutesDifference += 60;
          hoursDifference--;
        }
      
        // Handle negative hours (previous day)
        if (hoursDifference < 0) {
          hoursDifference += 24; // Assuming both times are on the same day
        }
        setFtime(hoursDifference)
        setFMin(minutesDifference)

     },[])
   





  
           
            

  return (


    <>
    <div className="w-full flex flex-col h-[315px] rounded-tr-[5rem] 
    rounded-tl-[5rem] shadow-2xl hover:bg-gradient-to-r from-[#7114ef] to-[#1475f7] 
    hover:!text-[white] BodyAnim  text-[#000000cf]
     mb-2 bg-white  cursor-pointer relative">

        {/* <div className='w-full 
      360px:w-[40%] 480px:w-[17%] sm:w-[47%] z-10   h-[25%] 
           flex  justify-center  absolute lg:w-[17%]'> */}
            <div className='w-full h-full hover:hidden hover:shadow-none
            flex justify-center  absolute top-0 
             '>

           

        
          <div className="h-[99px] shadow-lg 
          bg-gradient-to-r from-[#6f14ef28] to-[#1476f738]
           w-[99px]  rotate-45 transform origin-bottom-left"></div>


    </div>
        

        <div className='w-full flex text-center justify-center mt-5
        items-center z-10 font-[600] p-2 '>
            <h1 className='text-[20px] 800px:text-[25px]  '>
               {key && key}
               </h1>
            

        </div>
        <div className='w-full flex justify-center items-center z-10  p-2'>
             <h1 className='text-[20px] 800px:text-[22px] font-[600] mr-3'>Time: </h1>
             <h2 className='text-[19px] 800px:text-[21px] font-[600] pt-1'> {time}</h2>
        </div>

        <div className='w-full flex justify-center z-10   hover:text-[white] items-center p-2'>
             <h1 className='text-[20px] 800px:text-[22px] font-[600] mr-3 '>Old: </h1>
             <h2 className='text-[19px] 800px:text-[21px] font-[600] text-[#da3939] '> {old}</h2>
        </div>

        <div className='w-full flex justify-center z-10 hover:text-[white]  Blink items-center p-2'>
             <h1 className='text-[20px] 800px:text-[22px] mr-3   font-[600]  '>New: </h1>
             <h2 className='text-[19px] 800px:text-[21px] font-[600]  text-[green] 

             '> {ne}</h2>
        </div>
        <div className='w-full flex-col text-center  justify-center z-10 
        hover:text-[white] TimeBlink  items-center p-1'>
             <h1 className='text-[19px] text-center  800px:text-[19px] mr-3   font-[600] '>Time Left : </h1>
             <h2 className='text-[15px] text-center 800px:text-[16px] font-[600] '>{ftime} hours {fmin} minutes </h2>
        </div>
       

    </div>
    

    
    
    
    </>
  )
}

export default ProductCard