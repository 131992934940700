import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './AllFiles/Home';
import FAQ from './AllFiles/Component/FooterPages/FAQ';
import PrivacyPolicy from './AllFiles/Component/FooterPages/PrivacyPolicy';
import Disclaimer from './AllFiles/Component/FooterPages/Disclaimer'
import TermAndCondition from './AllFiles/Component/FooterPages/TermAndCondition'
import MonthChart from './AllFiles/Component/MonthChart';
import MonthGameChart from './AllFiles/Component/MonthGameChart';


function App() {
  return (
    <div className="App">
       
      <Router>
        <Routes>
          <Route path='/' element={<Home/>}></Route>
          <Route path='/yearchart' element={<MonthGameChart/>}></Route>
          <Route path='/faq' element={<FAQ/>}></Route>
          <Route path='/privacypolicy' element={<PrivacyPolicy/>}></Route>
          <Route path='/disclaimer' element={<Disclaimer/>}></Route>
          <Route path='/Term&Conditions' element={<TermAndCondition/>}></Route>
          
         
        </Routes>
      </Router>

      
    </div>
  );
}

export default App;
