import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

function Footer() {
  const navigate = useNavigate()
  const[active,setActive] = useState(0)
  const Faq = ()=>{
  
    navigate('/faq')
  }
  const PAP= ()=>{
    setActive(1)
    navigate('/privacypolicy')
    
  }
  const Disclaim =() =>{
    navigate('/disclaimer')
  }
  const Term =()=>{
    navigate('/Term&Conditions')
  }

  return (
    <>
    <div className='w-full mt-5 py-5 800px:hidden 
    font-[600] text-[18px] items-center text-center  flex-col  800px:justify-between px-6 
     bg-[#7114ef] text-[#ffffffd3] 800px:flex-row '>

   
     

     <h2 onClick={()=>PAP()}  
      className={`pb-3 cursor-pointer 800px:pb-0 `} >
        
      
      Privacy Policy
     
      
      </h2>

      <h2 onClick={()=>Disclaim()}
       className='pb-3 cursor-pointer 800px:pb-0'>
               

     
      Disclaimer 
    
      
      </h2>
      <h2 onClick={()=>Term()}
       className='pb-3 cursor-pointer 800px:pb-0'>
      
      Term & Condition
     
        
        </h2 >

        <h2 className='cursor-pointer pb-3 800px:pb-0' onClick={()=>Faq()}>
        
        FAQ
      
         
        </h2>


      
    </div>

<div className='w-[100wh]   mt-5 800px:flex py-5 hidden h-full px-8 
font-[600] text-[18px] items-center  bg-cover text-center justify-between 
  bg-[#7114ef] text-[#ffffffd3] '>
     <h2 onClick={()=>PAP()}
      className='pb-3 cursor-pointer 800px:pb-0' >
      
      Privacy Policy
     
      
      </h2>

      <h2  onClick={()=>Disclaim()}
      className='pb-3 cursor-pointer 800px:pb-0'>
      
      Disclaimer 
    
      
      </h2>
      <h2 onClick={()=>Term()}
      className='pb-3 cursor-pointer 800px:pb-0'>
      
      Term & Condition
      
        
        </h2 >

        <h2 className='cursor-pointer pb-3 800px:pb-0' onClick={()=>Faq()}>
        
        FAQ
      
         
        </h2>


    </div>
    </>
  )
}

export default Footer