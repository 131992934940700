import React from 'react'

const Header = () => {
  return (
    <div className=''>
        <div className='w-full mb-4 '>
        
        <h1 className='text-[50px] shadow-md Satta 
        font-extrabold text-center'>FAST SATTA RESULTS</h1>
        </div>
    </div>
  )
}

export default Header